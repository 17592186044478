
export default {
  name: "Picture",
  props: {
    image: {
      type: Object,
    },
    loadImage: Boolean,
    description: String,
  },
};
