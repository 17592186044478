
import Vue from "vue";

export default Vue.extend({
  name: "NavLinkItem",
  props: {
    link: String,
    imgSrc: String,
    label: String,
  },
});
