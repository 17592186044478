
import Vue from "vue";
import Picture from "@/components-ui/Picture/Picture.vue";
import { Home } from "./Home.interface";
import arbreFleuriJpg from "@/assets/images/pictures/arbre_fleuri.jpg";
import arbreFleuriWebp from "@/assets/images/pictures/arbre_fleuri.webp";
import arbreFleuriWebpMobile from "@/assets/images/pictures/arbre_fleuri-mobile.webp";
import nenupharJpg from "@/assets/images/pictures/plateau_nenuphar.jpg";
import nenupharWebp from "@/assets/images/pictures/plateau_nenuphar.webp";
import nenupharWebpMobile from "@/assets/images/pictures/plateau_nenuphar-mobile.webp";
import defaultImageForSharingJpg from "@/assets/images/pictures/nathalie_de_loeper_praticienne_shiatsu.jpg";
import { homePageSchemaContext } from "@/data/googleSchemas";

export default Vue.extend({
  name: "Home",
  components: {
    Picture,
  },
  data(): Home {
    return {
      landingPicture: {
        defaultImage: arbreFleuriJpg,
        imageWebp: arbreFleuriWebp,
        imageWebpMobile: arbreFleuriWebpMobile,
        loadImage: true,
      },
      nenuphar: {
        defaultImage: nenupharJpg,
        imageWebp: nenupharWebp,
        imageWebpMobile: nenupharWebpMobile,
        loadImage: false,
      },
    };
  },
  methods: {
    lazyLoadImage(section: MouseEvent): void {
      if (section.offsetY > 200) {
        this.nenuphar.loadImage = true;
      }
    },
  },
  metaInfo() {
    return {
      title: "Nathalie de Loeper, votre praticienne de Shiatsu",
      meta: [
        {
          property: "og:title",
          content: "Nathalie de Loeper, votre praticienne de Shiatsu",
        },
        {
          property: "og:url",
          content: "https://www.shiatsutherapie78.info",
        },
        {
          name: "Description",
          content:
            "Découvrez la pratique thérapeutique unique au contact de Nathalie de Loeper, praticienne diplômé de  l'E.S.T (Ecole de Shiatsu Thérapeutique) affiliée à l’UFPST (Union Française des Professionnels de Shiatsu Thérapeutique).",
        },
        { property: "og:type", content: "website" },
        {
          property: "og:description",
          content:
            "Découvrez la pratique thérapeutique unique au contact de Nathalie de Loeper, praticienne diplômé de  l'E.S.T (Ecole de Shiatsu Thérapeutique) affiliée à l’UFPST (Union Française des Professionnels de Shiatsu Thérapeutique).",
        },
        {
          property: "og:image",
          content: `https://www.shiatsutherapie78.info${defaultImageForSharingJpg}`,
        },
      ],
      script: [
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify(homePageSchemaContext),
        },
      ],
    };
  },
});
