
import Vue from "vue";
import { MetaInfo } from "@/types/metaInfo.interface";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default Vue.extend({
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      ogUrl: this.$store.state.domains.baseUrlFrontend + this.$store.state.domains.hash,
    };
  },
  metaInfo(): MetaInfo {
    return {
      meta: [
        {
          property: "og:url",
          content: this.ogUrl,
        },
      ],
    };
  },
});
