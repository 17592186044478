
import NavLinkItem from "./NavLinkItem.vue";

import nenuphar from "@/assets/images/logos/nenuphar.svg";
import home from "@/assets/images/logos/home.svg";
import about from "@/assets/images/logos/about.svg";
import blog from "@/assets/images/logos/blog.svg";
import reviews from "@/assets/images/logos/reviews.svg";
import contact from "@/assets/images/logos/contact.svg";

export default {
  components: {
    NavLinkItem,
  },
  data() {
    return {
      nenuphar,
      navlinks: {
        home,
        about,
        blog,
        reviews,
        contact,
      },
    };
  },
};
